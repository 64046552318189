import React from 'react'
import { Container } from 'react-bootstrap'

function Datenschutz() {
    return (
        <div>
            <Container className="overflow-auto mainContent">
            <h1> Datenschutz </h1>
            <h3>Grundlegendes</h3>
            <p>Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber <br />
                <br />
                Stieglitz und Jüttendonk GbR (Singulaer) <br />
                Am Blumenhaus 66    <br />
                45549 Sprockhövel <br />
                <br />
                informieren. <br />
                <br />

                Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.

                Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
            </p>
            
            <h3>Zugriffsdaten</h3>
            <p>Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als “Server-Logfiles” auf dem Server der Website ab. Folgende Daten werden so protokolliert:</p>
            <ul>
                <li> Besuchte Website</li>
                <li> Uhrzeit zum Zeitpunkt des Zugriffes</li>
                <li> Menge der gesendeten Daten in Byte</li>
                <li> Quelle/verweis, von welchem sie auf die Seite gelangten</li>
                <li> Verwendeter Browser </li>
                <li> Verwendetes Betriebssystem </li>
                <li> Verwendete IP-Adresse</li>
            </ul>
            <p>Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.</p>

            <h3>Reichweitenmessung &amp; Cookies</h3>
            <p>Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter an den Browser des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website.</p>
            <p>Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien hier widersprechen:</p>
            <ul>
                <li>Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative: <a href="http://optout.networkadvertising.org/?c=1#!/">http://optout.networkadvertising.org/?c=1#!/</a></li>
                <li>Cookie-Deaktivierungsseite der US-amerikanischen Website: <a href="http://optout.aboutads.info/?c=2#!/"></a>http://optout.aboutads.info/?c=2#!/</li>
                <li>Cookie-Deaktivierungsseite der europäischen Website: <a href="http://optout.networkadvertising.org/?c=1#!/"></a>http://optout.networkadvertising.org/?c=1#!/</li>
            </ul>
            <p>Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.</p>

            <h3>SSL-Verschlüsselung (HTTPS-Protokoll)</h3>
            <p>Um Ihre übermittelten Daten bestmöglich zu schützen nutzen die Websitebetreiber eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix “https://“ im Seitenlink in der Adresszeile Ihres Browsers. Unverschlüsselte Seite sind durch „http://“ gekennzeichnet.</p>
            <p>Sämtliche Daten, welche Sie an diese Website übermitteln – etwa bei Anfragen oder Logins – können dank SSL-Verschlüsselung nicht von Dritten gelesen werden.</p>

            <h3>Rechte des Nutzers</h3>
            <p>Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.</p>
            <h4>Löschung von Daten</h4>
            <p>Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.</p>
            <h4>Widerspruchsrecht</h4>
            <p>Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.
                Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: singulaer@gmx.de</p>
            </Container>
        </div>
    )
}

export default Datenschutz
