import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from 'react-router-bootstrap'

import logo from './singulaer_v2.png'

function Navigation() {
    return (
        <Navbar bg="light" expand="lg" style={{padding: 0}}>
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand><img alt='Singulaer Logo' style={{maxHeight: "70px"}} src={logo}></img></Navbar.Brand>
                </LinkContainer>
                
                <Navbar.Toggle aria-controls="navbar-nav"/>
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="navItems">
                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/produktbilder">
                            <Nav.Link>Produktbilder</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="/impressum">
                            <Nav.Link>Impressum</Nav.Link>
                        </LinkContainer>
                        
                        <LinkContainer to="/datenschutz">
                            <Nav.Link>Datenschutz</Nav.Link>
                        </LinkContainer>
                        
                        <LinkContainer to="/widerrufsbelehrung">
                            <Nav.Link>Widerrufsbelehrung</Nav.Link>
                        </LinkContainer>
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )
}

export default Navigation
