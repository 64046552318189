import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import './index.css';
import App from './App';
import { Helmet } from 'react-helmet'
import Widerrufsbelehrung from './Widerrufsbelehrung';
import Home from './Home/Home';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import Produktbilder from './Produktbilder/Produktbilder';

const TITLE = "Singulaer"

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <React.StrictMode>
    <Helmet>
      <title> {TITLE}</title>
    </Helmet>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="widerrufsbelehrung" element={<Widerrufsbelehrung />} />
          <Route path="impressum" element={<Impressum />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="produktbilder" element={<Produktbilder />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

