import React from 'react'
import Container from 'react-bootstrap/Container'
import { FaInstagram, FaEtsy, FaFacebook } from "react-icons/fa";

function Footer() {
    return (
        <footer className="text-center bg-light fixed-bottom">
            <Container className="pb-1 pt-1">
            <a className="px-2" style={{ color: '#ac2bac' }} href="https://www.instagram.com/singulaer_made_by_lue/" role="button"><FaInstagram size={30}></FaInstagram></a>
            <a className="px-2" style={{ color: '#d5641c' }} href="https://etsy.com/de/shop/Singulaer" role="button"><FaEtsy size={25}></FaEtsy></a>
            <a className="px-2" style={{ color: '#4267B2' }} href="https://www.facebook.com/singulaerMadeByLue" role="button"><FaFacebook size={25}></FaFacebook></a>
            </Container>
        </footer>
    )
}

export default Footer
