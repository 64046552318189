import React from 'react'
import { Container } from 'react-bootstrap'

function Widerrufsbelehrung() {
    return (
        <div>
            <Container className="overflow-auto mainContent">
<div>
    <h1>Widerrufsbelehrung</h1>
<br />
Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
<br />
<br /><h3>Widerrufsrecht</h3><br /><br />

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Firma Stieglitz und Jüttendonk GbR (Singulaer), Am Blumenhaus 66, 45549 Sprockhövel , E-Mail-Adresse: singulaer@gmx.de) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.<br /><br />

<h3>Folgen des Widerrufs</h3><br />
<br />

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.  Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</div><br />
<div><h3>Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts</h3><br />
<br />
Das Widerrufsrecht besteht nicht bei Verträgen<br />
<br />
- zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;<br />
- zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;<br />
- zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat;<br />
- zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit Ausnahme von Abonnement-Verträgen.<br />
<br />

Das Widerrufsrecht erlischt vorzeitig bei Verträgen<br />
<br />
- zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;<br />
- zur Lieferung von Waren, wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;<br />
- zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.</div><br /><div className="white-box" style={{border: "5px solid #777777"}}><div align="center"><h3>Muster-Widerrufsformular</h3></div><br />

<p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>An<br />
Firma<br /> 
Stieglitz und Jüttendonk GbR (Singulaer)<br />
Am Blumenhaus 66<br />
45549 Sprockhövel<br />
<br />
E-Mail-Adresse: singulaer@gmx.de<br />
<br />

<br />
- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistungen <br />
_________________________________<br />
Bestellt am (*)/erhalten am (*)<br />
<br />
________________________________<br />
Name des/der Verbraucher(s)<br />
<br />
__________________________________<br />
Anschrift des/der Verbraucher(s)<br />
<br />
__________________________________<br />
Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br />
<br />
___________________<br />
Datum<br />
---------------------------------------<br />
(*) Unzutreffendes streichen.

<p><em>Quelle: <a href="https://www.ra-plutte.de/widerrufsbelehrung-generator/" target="_blank" alt="Widerrufsbelehrung Generator">Erstellt mit dem Widerrufsbelehrung Generator der Kanzlei Plutte.</a></em></p></div>
        
    </Container>
    </div>
    )
}

export default Widerrufsbelehrung
