import React from 'react'
import ImageCard from './ImageCard'
import Container from 'react-bootstrap/Container'
import { Row } from 'react-bootstrap'

import image1 from './cardImage1.jpg'
import image2 from './cardImage2.jpg'
import image3 from './cardImage3.jpg'
import image4 from './cardImage4.jpg'
import image5 from './cardImage5.jpg'
import image6 from './cardImage6.jpg'

function Produktbilder() {
    return (
        <Container className="overflow-auto mainContent">
            <Row>
            <ImageCard image={image1}/>
            <ImageCard image={image2}/>
            <ImageCard image={image3}/>
            <ImageCard image={image4}/>
            <ImageCard image={image5}/>
            <ImageCard image={image6}/>
            </Row>
            
                        
        </Container>
    )
}

export default Produktbilder
