import React from 'react'
import Container from 'react-bootstrap/Container'

function Impressum() {
    return (
        <div>
        <Container className="overflow-auto mainContent">
            <h1> Impressum </h1>
            <p>
                Stieglitz GbR (Singulaer) <br />
                Könisgberger Str. 45 <br />
                42277 Wuppertal <br />
                Ust-Id: DE346262191 <br />
                singulaer@gmx.net
            </p>
            <p>
                Vertreten durch : <br />
                Jacqueline Stieglitz <br />
            </p>
            <p>
                Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a></p>

            <p> Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
            </p>
        </Container>
        </div>
    )
}

export default Impressum
