import './App.scss';
import Navigation from './Navigation';
import Footer from './Footer';

import { Outlet } from 'react-router-dom';


function App() {
  return (
    <div className="App" style={{ backgroundColor: "#e6e4e5", height: "1vh"}}>
        <Navigation/>
        <Outlet />
        <Footer/>
    </div>
  );
}

export default App;
