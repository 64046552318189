import React from 'react'
import { CardImg } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'


const ImageCard = props => (
        <div className="col-11 col-sm-6 col-lg-3 my-3">
            <Card style={{border: "9px solid white", borderRadius: "0"}}>
                <Card.Img src={props.image} />
            </Card>            
        </div>
    );

export default ImageCard;
