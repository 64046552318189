import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

import img1 from './homeImage1.jpg'
import img2 from './homeImage2.jpg'

function Home() {
    return (
        <div>
            <Carousel className="imageContainer" style={{ border:"2px white", borderStyle:"dotted none dotted none"}} interval="99999999" keyboard="false" className="my-1">
                {/*<Carousel.Item className="mainContent">
                    <p className="py-4 px-1 w-100 align-middle">
                    Lorem ipsum dolor sit amet, consetetur sadipscing eli <br/>
                    Hello Mello? <br />
                    Baby you called, I can't hear a thing <br />
                    I have got no service In the club, you see, see <br />
                    Wha-wha-what did you say? Oh you're breaking up on me <br />
                    </p>
                </Carousel.Item>*/}

                <Carousel.Item className="imageContainer">
                    <img src={img1} className="d-block w-100" alt=""></img>
                </Carousel.Item>

                <Carousel.Item className="imageContainer">
                    <img src={img2} className="d-block w-100" alt=""></img>
                </Carousel.Item>

            </Carousel>

            <Link className="btn btn-primary my-1" to="/produktbilder">
                Zu den Produktbildern
            </Link>
        </div>
    )
}

export default Home
